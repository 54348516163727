<template>
    <div class="app-container">
        <el-row :gutter="24" style="margin:20px">
            <el-col
                :span="4"
                style="background-color: #fff"
                class="left-box"
            >
               <Left :id="id" @search="search"></Left>
            </el-col>
            <el-col
                :span="15"
                class="content-box"
            >
            <Content :id="id" ref="content"></Content>
            </el-col>
            <el-col
                :span="5"
                style="background-color: #fff"
                class="right-box"
            >
               <Right :id="id"  ref="right" @search="search"></Right>
            </el-col>
        </el-row>
        <div class="flex">
            <div class="btn-group-vertical" style="width: 100%">
                <el-button
                    style="z-index:1;position: relative;"
                    type="button"
                    class="btn btn-outline-secondary"
                    @click.native="downloadAnalysis()"
                    :loading="loading.form6"
                >
                    <i class="fa fa-download"></i> 下载分析结果
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="downloadCompany()"
                    :loading="loading.form4"
                >
                    <i class="fa fa-download"></i> 下载参与企业及联系方式
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="downloadnoCompany()"
                    :loading="loading.form5"
                >
                    <i class="fa fa-download"></i> 下载未填报企业及联系方式
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="doDownloadAnswer(0)"
                    :loading="loading.form2"
                >
                    <i class="fa fa-download"></i> 下载所有问卷数据
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="doDownloadAnswer(1)"
                    :loading="loading.form3"
                >
                    <i class="fa fa-download"></i> 下载所有问卷数据（编码）
                </el-button>
                <el-button
                    type="button"
                    class="btn btn-outline-secondary"
                    @click="downloadinformationmanagement()"
                    :loading="loading.form1"
                >
                    <i class="fa fa-download"></i> 双稳平台推送信息管理
                </el-button>
            </div>
        </div>
        <Model ref="model" @Loadding="loadingClick"></Model>
    </div>
</template>
<script>
import Left from './components/left.vue'
import Content from './components/content.vue'
import Right from './components/right.vue'
import Model from './components/model.vue'

import {
    getNewIdApi,
} from '@/api/analysisResultApi'
export default {
    components:{Left,Content,Right, Model},
    data() {
        return {
            loading:{
                form1:false,
                form2:false,
                form3:false,
                form4:false,
                form5:false,
                form6:false,
            },
            id: null,
            leftValue:{
                
            },
        }
    },
    async created() {
        if (!this.$route.query.id) {
            // this.id = await this.getNewIdFun()
            this.id = 16
        } else {
            this.id = this.$route.query.id
        }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        // 左侧数据返回的送搜索数据
        search(value){
            this.leftValue = value
            console.log('value', value);
            this.$refs.content.open(value)
            this.$refs.right.open(value)
            // this.leftParams= value

        },
        // 弹窗返回数据
        loadingClick(value,code){
            
            if (value === 'surveyBistablePlatformApi') {
                this.loading.form1 = false
            } else if (value === 'surveyDownloadApi') {
                
                if (code === 0) {
                    this.loading.form2 = false
                } else {
                    this.loading.form3 = false
                }
            } else if (value === 'surveyDownloadCompanyTelApi') {
                this.loading.form4 = !this.loading.form4
            } else if (value === 'surveyDownloadNotCompanyTelApi') {
                this.loading.form5 = false
            } else{
                this.loading.form6 = false
                console.log('this.loading.form6', this.loading.form6);
            }
        },
          // 获取最新问卷ID
        async getNewIdFun() {
            var res = await getNewIdApi()
            return res.code === 200 ? res.data : null
        },
        // 下载分析结果
        downloadAnalysis() {
            if(this.$refs.content.question.length>0){
                this.loading.form6=true
                this.$refs.model.open(this.$refs.content.question)
            }else{
                 this.$message({
                    message: '请稍等',
                    type: 'warning',
                })
            }
            
            
        },
        // 下载参与企业及联系方式
        downloadCompany() {
            this.loading.form4=true

            this.$refs.model.open('surveyDownloadCompanyTelApi',this.leftValue)
        },
        // 下载未填报企业及联系方式
        downloadnoCompany() {
            this.loading.form5=true
            this.$refs.model.open('surveyDownloadNotCompanyTelApi', this.leftValue)
        },
        // 下载所有问卷数据:0   下载所有问卷数据（编码）:1
        doDownloadAnswer(code) {
            if(code===0){
                this.loading.form2=true
            }else{
                this.loading.form3=true
            }
            this.$refs.model.open('surveyDownloadApi', null,code)
        },
        // 双稳平台推送信息管理
        downloadinformationmanagement() {
            this.loading.form1=true
            this.$refs.model.open('surveyBistablePlatformApi')
        },
    },
}
</script>
<style scoped>
.app-container{
    height: calc(100vh - 150px);
    margin: 0;
    /* margin-bottom: 0; */
    /* overflow: hidden; */
}
.company {
    background-color: rgba(242, 242, 242, 1);
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 10px;
}
.company-name {
    font-size: 12px;
    color: #409eff;
}
.company-rate {
    font-size: 12px;
    color: #19be6b;
}
.company-uscc,
.company-city {
    font-size: 12px;
    color: #333;
    word-wrap: break-word;
}
.company-dateTime {
    font-size: 12px;
    color: #333;
}
.company-noTime {
    font-size: 12px;
    color: #ff0000;
}
.left-box {
    /* height: 100%; */
    height: calc(100vh - 195px);
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-bottom: 48px; */
}
.content-box{
   /* height: calc(100vh - 180px);  */
}
.left-box::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
.left-box::-webkit-scrollbar-track {
    background-color: #fff;
}
.left-box::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 7px;
}
.right-box {
    /* height: 100%; */
    height: calc(100vh - 195px);
    overflow-y: auto;
    overflow-x: hidden;
    /* margin-bottom: 48px; */
    padding-bottom: 10px;
}
.right-box::-webkit-scrollbar {
    width: 7px;
}
.right-box::-webkit-scrollbar-track {
    background-color: #fff;
}
.right-box::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 7px;
}
.nav-item {
    list-style-type: none;
}
a {
    color: #000;
    text-decoration: none;
    margin-left: 7px;
}
.a-hover {
    color: rgb(24, 144, 255);
}
.nav-li {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #474747;
}
.nav-li-hover {
    background-color: rgb(24, 144, 255);
}
/* .table {
    width: 100%;
    border-collapse: collapse;
} */
/* .text-center {
    text-align: center;
}
tr {
    height: 40px;
}
th {
    padding: 0;
    background-color: rgba(250, 250, 250, 1);
}
td {
    border-bottom: 1px solid #ccc;
    padding: 0;
} */
::v-deep .el-progress {
    display: flex;
}
.flex {
    /* width: 100%; */
    position: sticky;
    bottom: 0px;
    /* left: 0;
    right: 18px; */
    padding: 10px;
    background-color: #fff;
    text-align: center;
}
.content-box {
}
/* .echarts-content {
    height: calc(100vh - 270px);
    overflow: auto;
} */
.echarts-content::-webkit-scrollbar {
    width: 7px;
}
.echarts-content::-webkit-scrollbar-track {
    background-color: #fff;
}
.echarts-content::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 7px;
}
</style>
